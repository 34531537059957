import { useCallback, useMemo } from 'react';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import { getCurrentCar } from '@/shared/lib/utils.ts';

export const useArrows = (goToCarByIndex: (index: number) => void) => {
    const { events } = useCanvasStore();
    const { isDragAvailable, carInfo, carsData } = events.get();

    const currentCarIndex = useMemo(() => getCurrentCar() + 1, [carInfo]);
    const isPrevAllow = useMemo(() => currentCarIndex > 1, [currentCarIndex]);
    const isNextAllow = useMemo(
        () => currentCarIndex < carsData.length,
        [currentCarIndex, carsData],
    );

    const onNext = useCallback(() => {
        goToCarByIndex(currentCarIndex + 1);
    }, [currentCarIndex, goToCarByIndex]);

    const onPrev = useCallback(() => {
        goToCarByIndex(currentCarIndex - 1);
    }, [currentCarIndex, goToCarByIndex]);

    return {
        isDragAvailable,
        isPrevAllow,
        isNextAllow,
        onPrev,
        onNext,
    };
};
