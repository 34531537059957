// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#widget {
    fill: red;
}

.model-range-canvasHover {
    cursor: pointer;
}

.bottom-text-wrapper {
    display: flex;
    position: absolute;
    bottom: 80px;
    left: 200px;
    right: 200px;
    gap: 20px;
}

.bottom-text-wrapper-arrows {
  gap: 52px;
  margin-bottom: 8px;
  display: none;
}

.model-range-fade-in {
    opacity: 0;
    animation: fadeInAnimation 1s ease-in;
}

@media screen and (max-width: 1023px) {
    .bottom-text-wrapper {
        left: 0;
        right: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        bottom: 44px;
    }
}

@media screen and (max-width: 500px) {
  .bottom-text-wrapper-arrows {
    display: flex;
  }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
`, "",{"version":3,"sources":["webpack://./src/features/canvas/ui/Canvas.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,SAAS;AACb;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,aAAa;AACf;;AAEA;IACI,UAAU;IACV,qCAAqC;AACzC;;AAEA;IACI;QACI,OAAO;QACP,QAAQ;QACR,sBAAsB;QACtB,uBAAuB;QACvB,mBAAmB;QACnB,YAAY;IAChB;AACJ;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":["#widget {\n    fill: red;\n}\n\n.model-range-canvasHover {\n    cursor: pointer;\n}\n\n.bottom-text-wrapper {\n    display: flex;\n    position: absolute;\n    bottom: 80px;\n    left: 200px;\n    right: 200px;\n    gap: 20px;\n}\n\n.bottom-text-wrapper-arrows {\n  gap: 52px;\n  margin-bottom: 8px;\n  display: none;\n}\n\n.model-range-fade-in {\n    opacity: 0;\n    animation: fadeInAnimation 1s ease-in;\n}\n\n@media screen and (max-width: 1023px) {\n    .bottom-text-wrapper {\n        left: 0;\n        right: 0;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        bottom: 44px;\n    }\n}\n\n@media screen and (max-width: 500px) {\n  .bottom-text-wrapper-arrows {\n    display: flex;\n  }\n}\n\n@keyframes fadeInAnimation {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
