// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-range-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #ffffff;
}

.model-range-loader-dot {
    width: 10px;
    height: 10px;
    border: 2px solid black;
    border-radius: 50%;
    margin: 0 5px;
    transform: scale(0);
    -webkit-animation: fx 1000ms ease infinite 0ms;
    animation: fx 1000ms ease infinite 0ms;
}

.model-range-loader-dot:nth-child(2) {
    -webkit-animation: fx 1000ms ease infinite 300ms;
    animation: fx 1000ms ease infinite 300ms;
}

.model-range-loader-dot:nth-child(3) {
    -webkit-animation: fx 1000ms ease infinite 600ms;
    animation: fx 1000ms ease infinite 600ms;
}

@keyframes fx {
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/features/loader/ui/AppLoader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;IACP,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8CAA8C;IAC9C,sCAAsC;AAC1C;;AAEA;IACI,gDAAgD;IAChD,wCAAwC;AAC5C;;AAEA;IACI,gDAAgD;IAChD,wCAAwC;AAC5C;;AAEA;IACI;QACI,mBAAmB;QACnB,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".model-range-loader {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    background-color: #ffffff;\n}\n\n.model-range-loader-dot {\n    width: 10px;\n    height: 10px;\n    border: 2px solid black;\n    border-radius: 50%;\n    margin: 0 5px;\n    transform: scale(0);\n    -webkit-animation: fx 1000ms ease infinite 0ms;\n    animation: fx 1000ms ease infinite 0ms;\n}\n\n.model-range-loader-dot:nth-child(2) {\n    -webkit-animation: fx 1000ms ease infinite 300ms;\n    animation: fx 1000ms ease infinite 300ms;\n}\n\n.model-range-loader-dot:nth-child(3) {\n    -webkit-animation: fx 1000ms ease infinite 600ms;\n    animation: fx 1000ms ease infinite 600ms;\n}\n\n@keyframes fx {\n    50% {\n        transform: scale(1);\n        opacity: 1;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
