// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.model-range-arrow {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  width: 120px;
  height: 120px;
  opacity: 0;
  cursor: pointer;
  transition: opacity 1s linear;
}

.model-range-arrow:last-child {
  transform: scale(-1, 1);
}

.model-range-animated-arrow {
  animation: flickerAnimation 2s infinite ease-in-out alternate;
}

.model-range-arrow-hidden {
  pointer-events: none;
  animation-name: none;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.05;
  }
}

@media screen and (max-width: 1520px) {
  .model-range-arrow {
    width: 80px;
    height: 80px;
  }
}

@media all and (max-width: 500px) {
  .model-range-arrow {
    width: 52px;
    height: 52px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/arrows/ui/Arrows.css"],"names":[],"mappings":";AACA;EACE,yBAAyB,EAAE,0BAA0B;EACrD,sBAAsB,EAAE,YAAY;EACpC,qBAAqB,EAAE,2BAA2B;EAClD,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,UAAU;EACV,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,6DAA6D;AAC/D;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":["\n.model-range-arrow {\n  -webkit-user-select: none; /* Chrome, Safari, Opera */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n  width: 120px;\n  height: 120px;\n  opacity: 0;\n  cursor: pointer;\n  transition: opacity 1s linear;\n}\n\n.model-range-arrow:last-child {\n  transform: scale(-1, 1);\n}\n\n.model-range-animated-arrow {\n  animation: flickerAnimation 2s infinite ease-in-out alternate;\n}\n\n.model-range-arrow-hidden {\n  pointer-events: none;\n  animation-name: none;\n}\n\n@keyframes flickerAnimation {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0.05;\n  }\n}\n\n@media screen and (max-width: 1520px) {\n  .model-range-arrow {\n    width: 80px;\n    height: 80px;\n  }\n}\n\n@media all and (max-width: 500px) {\n  .model-range-arrow {\n    width: 52px;\n    height: 52px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
