// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-range-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  max-width: 1008px;
  min-height: 158px;
  left: 50%;
  transform: translate(-50%);
  min-width: max-content;
  width: 100%;
}

@media screen and (max-width: 1520px) {
    .model-range-header {
        max-width: 870px;
        min-height: 158px;
    }
}

@media all and (max-width: 800px) {
    .model-range-header {
      max-width: 442px;
      min-height: 142px;
    }
}

@media all and (max-width: 500px) {
    .model-range-header .model-range-arrow {
        display: none;
    }

    .model-range-header {
      min-height: unset;
      max-width: unset;
      justify-content: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/features/header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,MAAM;EACN,iBAAiB;EACjB,iBAAiB;EACjB,SAAS;EACT,0BAA0B;EAC1B,sBAAsB;EACtB,WAAW;AACb;;AAEA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ;;AAEA;IACI;MACE,gBAAgB;MAChB,iBAAiB;IACnB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;MACE,iBAAiB;MACjB,gBAAgB;MAChB,uBAAuB;IACzB;AACJ","sourcesContent":[".model-range-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  max-width: 1008px;\n  min-height: 158px;\n  left: 50%;\n  transform: translate(-50%);\n  min-width: max-content;\n  width: 100%;\n}\n\n@media screen and (max-width: 1520px) {\n    .model-range-header {\n        max-width: 870px;\n        min-height: 158px;\n    }\n}\n\n@media all and (max-width: 800px) {\n    .model-range-header {\n      max-width: 442px;\n      min-height: 142px;\n    }\n}\n\n@media all and (max-width: 500px) {\n    .model-range-header .model-range-arrow {\n        display: none;\n    }\n\n    .model-range-header {\n      min-height: unset;\n      max-width: unset;\n      justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
