import { ReactNode } from 'react';
import { useArrows } from '@/features/arrows/model/useArrows.ts';
import ArrowIcon from '@/shared/assets/drag.svg';
import { classNames } from '@/shared/lib/utils.ts';
import './Arrows.css';

interface Props {
    children?: ReactNode;
    goToCarByIndex: (index: number) => void;
}
export const Arrows = ({ children, goToCarByIndex }: Props) => {
    const { isDragAvailable, isPrevAllow, isNextAllow, onNext, onPrev } =
        useArrows(goToCarByIndex);

    return (
        <>
            <div
                onClick={onPrev}
                className={classNames(
                    'model-range-arrow',
                    {
                        'model-range-animated-arrow': isDragAvailable,
                        'model-range-arrow-hidden': !isPrevAllow,
                    },
                    [],
                )}
            >
                <ArrowIcon />
            </div>
            {children}
            <div
                onClick={onNext}
                className={classNames(
                    'model-range-arrow',
                    {
                        'model-range-animated-arrow': isDragAvailable,
                        'model-range-arrow-hidden': !isNextAllow,
                    },
                    [],
                )}
            >
                <ArrowIcon />
            </div>
        </>
    );
};
