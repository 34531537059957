import { FC, useEffect } from 'react';
import { AppLoader } from '@/features/loader';
import { CarsCanvas } from '../model/Canvas.ts';
import { CanvasComponentType } from '../model/Canvas.types.ts';
import { listeners } from '../model/listeners.ts';
import { useCanvas } from '../model/useCanvas.tsx';
import { useCanvasController } from '../model/useCanvasController.ts';
import './Canvas.css';

export const Canvas: FC<CanvasComponentType> = ({
    renderHeader,
    bottomText,
    renderProgress,
    renderMobileArrows,
}) => {
    const {
        setCanvasInstance,
        canvasInstance,
        canvasRef,
        INITIAL_ANIMATION_FRAME_CENTER,
        INITIAL_ANIMATION_FRAME_START,
        setIsInitialized,
        isInitialized,
        setIsDragAvailable,
        setIsTextAnimationStarted,
        setIsDraggingActive,
        setIsMouseDown,
        setFrame,
        rerender,
        INITIAL_ANIMATION_FRAME_DELAY,
        renderCarInfo,
        frameCount,
        isTextAnimationStarted,
        inView,
        isViewed,
        setIsViewed,
        goToCarByIndex,
        sensitivity,
        isStartAnimationFramesLoaded,
    } = useCanvas();

    const {
        handleMouseUp,
        handleMouseDown,
        handleMouseLeave,
        handleCanvasInView,
        handleMouseMove,
    } = useCanvasController();

    useEffect(() => {
        setIsInitialized(true);
    }, []);

    useEffect(() => {
        if (!isInitialized || !canvasRef.current) {
            rerender();
            return;
        }
        setCanvasInstance(
            new CarsCanvas({
                canvas: canvasRef.current as HTMLCanvasElement,
                setFrame,
                initialFrameCount: INITIAL_ANIMATION_FRAME_START,
            }),
        );
        canvasInstance?.clearReact();
        canvasInstance?.drawImage();
    }, [isInitialized, canvasRef.current]);

    useEffect(() => {
        if (isViewed) {
            return;
        }
        if (isInitialized && canvasInstance && inView && isStartAnimationFramesLoaded) {
            handleCanvasInView(
                INITIAL_ANIMATION_FRAME_CENTER,
                INITIAL_ANIMATION_FRAME_START,
                INITIAL_ANIMATION_FRAME_DELAY,
                canvasInstance,
                setIsViewed,
                setIsTextAnimationStarted,
                setIsDragAvailable,
            );
        }
    }, [isInitialized, canvasInstance, inView, isViewed, isStartAnimationFramesLoaded]);

    useEffect(() => {
        renderCarInfo();
    }, [frameCount]);

    useEffect(() => {
        if (!canvasInstance || !canvasRef.current) {
            return;
        }

        const mouseDownCallback = () => {
            handleMouseDown(setIsMouseDown, isTextAnimationStarted);
        };

        const mouseUpCallback = () => {
            handleMouseUp(
                isTextAnimationStarted,
                setIsMouseDown,
                canvasInstance,
                INITIAL_ANIMATION_FRAME_DELAY,
            );
        };

        const mouseLeaveCallback = () => {
            handleMouseLeave(
                setIsMouseDown,
                canvasInstance,
                INITIAL_ANIMATION_FRAME_DELAY,
                isTextAnimationStarted,
            );
        };

        const mouseMoveCallback = (event: MouseEvent | TouchEvent) => {
            handleMouseMove(
                event,
                sensitivity,
                canvasRef,
                isTextAnimationStarted,
                rerender,
                setIsDraggingActive,
                canvasInstance,
            );
        };

        const removeListeners = listeners(
            canvasRef.current,
            mouseDownCallback,
            mouseUpCallback,
            mouseLeaveCallback,
            mouseMoveCallback,
        );

        canvasInstance?.initCanvasWithAnimation();

        return () => removeListeners();
    }, [canvasInstance, canvasRef.current, isTextAnimationStarted]);

    return (
        <div id="widget-container" style={{ width: '100%', height: '100%' }}>
            {renderHeader(goToCarByIndex)}
            <canvas
                id="widget"
                ref={canvasRef}
                width={canvasInstance?.canvasWidth}
                height={canvasInstance?.canvasHeight}
            />
            <div className="bottom-text-wrapper">
                <div className="bottom-text-wrapper-arrows">
                    {renderMobileArrows(goToCarByIndex)}
                </div>
                {bottomText}
                {renderProgress(goToCarByIndex)}
            </div>
            {!isStartAnimationFramesLoaded && <AppLoader />}
        </div>
    );
};
