import { Arrows } from '@/features/arrows/ui/Arrows.tsx';
import { Text } from '@/features/text';
import './Header.css';

export const Header = ({
    goToCarByIndex,
}: {
    goToCarByIndex: (index: number) => void;
}) => (
    <div className="model-range-header">
        <Arrows goToCarByIndex={goToCarByIndex}>
            <Text />
        </Arrows>
    </div>
);
