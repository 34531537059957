import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import { Arrows } from '@/features/arrows/ui/Arrows.tsx';
import { BottomText } from '@/features/bottomText';
import { Canvas } from '@/features/canvas';
import { Header } from '@/features/header/Header.tsx';
import { ImagesForLoading } from '@/features/ImagesForLoading';
import { FullProgress } from '@/features/progress/ui/FullProgress.tsx';
import './App.css';
import './styles/index.css';

function App() {
    const { ref, inView } = useInView({
        threshold: 0.3,
    });
    const {
        events: { setInView, setIsStartAnimationFramesLoaded },
    } = useCanvasStore();

    useEffect(() => {
        setInView(inView);
    }, [inView]);

    return (
        <div className="widget-wrapper" ref={ref}>
            <ImagesForLoading
                inView={inView}
                setIsStartAnimationFramesLoaded={setIsStartAnimationFramesLoaded}
            />
            <Canvas
                renderHeader={handleArrowIconClick => (
                    <Header goToCarByIndex={handleArrowIconClick} />
                )}
                renderMobileArrows={handleArrowIconClick => (
                    <Arrows goToCarByIndex={handleArrowIconClick} />
                )}
                bottomText={<BottomText />}
                renderProgress={handleProgressClick => (
                    <FullProgress handleProgressClick={handleProgressClick} />
                )}
            />
        </div>
    );
}

export default App;
