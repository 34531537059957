import { memo, useMemo } from 'react';
import { useCanvasStore } from '@/domain/canvasStore.tsx';
import Arrow from '@/shared/assets/arrow.svg';
import { classNames, transliterate } from '@/shared/lib/utils.ts';
import './BottomText.css';

export const BottomText = memo(() => {
    const { events } = useCanvasStore();
    const { isTextAnimationStarted, carInfo } = events.get();
    const isInStock = carInfo?.links && !carInfo?.isOutOfStock;
    const aboutModelLink = useMemo(
        () => carInfo?.links.find(link => link.id === 'about'),
        [carInfo],
    );

    return (
        <div
            className={classNames(
                'model-range-bottom-text',
                { 'model-range-animated-text': isTextAnimationStarted },
                [],
            )}
        >
            {isInStock && (
                <div className="model-range-container">
                    <a
                        target="_blank"
                        href={`/cars/#/?model=${transliterate(carInfo?.model)}`}
                        className={classNames('model-range-bottom-text-main', {}, [
                            carInfo?.links?.[0].design.style,
                        ])}
                        rel="noreferrer"
                    >
                        В наличии
                        <Arrow />
                    </a>
                </div>
            )}
            {aboutModelLink && (
                <div key={aboutModelLink.id} className="model-range-container">
                    <a
                        target="_blank"
                        href={aboutModelLink.url}
                        className={classNames('model-range-bottom-text-main', {}, [
                            aboutModelLink.design.style,
                        ])}
                        rel="noreferrer"
                    >
                        О модели
                        <Arrow />
                    </a>
                </div>
            )}
        </div>
    );
});
